import React, { useEffect } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Services.css';

function Services() {
	useEffect(() => {
		document.title = "Assisted Innovations technical competencies for creating change";
		trackPage();
	});
	return (
		<>
			<Header>
				<h1>Tailored Services to Empower Your Business</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						<p>At Assisted Innovations, we specialize in providing customized solutions for specialized tools and equipment businesses.</p>
						<p>Our services are designed to help your business operate more efficiently, improve your online presence, and drive growth.</p>
						<p>Here are the primary ways we support your success:</p>
					</div>
				</div>
				<div className="step-containers">
					<div className="step-container">
						<h2 className="step-title"><strong><em>Web Development</em></strong></h2>
						<div className="step-content">
							<p>
								Whether you need a content management system (CMS), a custom-built platform, or a powerful eCommerce solution, we've got you covered.
							</p>
							<ul>
								<li>
									<h3>CMS Development:</h3>
									<p>WordPress, Craft, Drupal, Odoo, etc.</p>
									<p>We build websites with the appropriate CMS and customize it to give you control without technical headaches.</p>
								</li>
								<li>
									<h3>Custom Development:</h3>
									<p>React, Node.js, Express.js, CMS Plugins, etc.</p>
									<p>Custom tailored solutions that meet your specific business needs, no matter how complex your workflows are.</p>
								</li>
								<li>
									<h3>eCommerce Development:</h3>
									<p>Magento, WooCommerce, BigCommerce, Shopify, etc.</p>
									<p>High-performance online stores designed to boost sales and provide a seamless shopping experience.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><strong><em>Website Management</em></strong></h2>
						<div className="step-content">
							<p>
								A website isn't a one-and-done project—it's a living tool that requires care to stay effective. We offer:
							</p>
							<ul>
								<li>
									<h3>Proactive Management:</h3>
									<p>Regular updates, performance monitoring, and security enhancements to keep your site running smoothly.</p>
								</li>
								<li>
									<h3>Reactive Support:</h3>
									<p>On-demand troubleshooting and fixes when unexpected issues arise, ensuring minimal downtime.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><strong><em>Integrations</em></strong></h2>
						<div className="step-content">
							<p>
								Your tools and software should work together seamlessly. We specialize in:
							</p>
							<ul>
								<li>
									<h3>Standard Integrations:</h3>
									<p>Connecting essential platforms like CRMs, email marketing tools, and inventory systems to streamline operations.</p>
								</li>
								<li>
									<h3>Custom Integrations:</h3>
									<p>Building tailored connections between your tools to automate workflows and eliminate inefficiencies.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='cta-block'>
					<div className="cta-items">
						<ul>
							<li className='cta-action'>
								<h3>Ready to take your business to the next level?</h3>
								<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Services;