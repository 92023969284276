import React, { useEffect } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Approach.css';

function Approach() {
	useEffect(() => {
		document.title = "Assisted Innovations makes software work for You";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>The Assisted Innovations Approach</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						<p>Growth is exciting, but it comes with its own set of challenges—especially when introducing new tools or upgrading your digital presence. That's why our five-stage approach is designed to do more than just deliver a website; it's built to uncover inefficiencies, optimize your processes, and create a seamless integration of tools that positions your business for sustainable success.</p>
						<p>We understand that even a seemingly straightforward change, like building a new website, can ripple through your operations in unexpected ways. Our process ensures that every aspect of your business—tools, workflows, and team dynamics—is considered, creating a foundation that supports growth without the growing pains.</p>
					</div>
				</div>
				<div className="step-containers">
					<div className="step-container">
						<h2 className="step-title"><span>Stage 1:</span>&nbsp;<strong><em>Understanding Your Business</em></strong></h2>
						<div className="step-content">
							<p>In this foundational stage, we immerse ourselves in your world to truly understand your team, tools, and processes. Bringing years of industry knowledge to the table, we approach this phase as a collaboration, ensuring we build solutions tailored to your unique needs and challenges. Our familiarity with specialized tools and equipment businesses allows us to quickly identify pain points and uncover opportunities that others might overlook.</p>
							<p>We focus on three critical areas:</p>
							<ol>
								<li>
									<h3>How you currently do business</h3>
									<p>We explore your workflows, customer interactions, and day-to-day operations to identify what's working well and where there's room for improvement. This helps us align your website's functionality with your business goals, whether it's streamlining sales, improving customer service, or showcasing your expertise.</p>
								</li>
								<li>
									<h3>Your Team's Comfort Level with Software</h3>
									<p>Not all teams are tech-savvy, and that's okay. We assess your team's familiarity with digital tools to ensure the solutions we deliver are intuitive, accessible, and easy to adopt. Training and support are part of the process if needed.</p>
								</li>
								<li>
									<h3>Identifying Manual Processes</h3>
									<p>Manual workflows can be time-consuming and prone to errors. We'll pinpoint areas where automation can save time, improve accuracy, and reduce effort—whether that's managing inventory, processing orders, or gathering customer data.</p>
								</li>
							</ol>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><span>Stage 2:</span>&nbsp;<strong><em>Strategic Planning	</em></strong></h2>
						<div className="step-content">
							<p>With a deep understanding of your business gained during the first stage, we move into strategic planning. This phase transforms insights into a clear, actionable roadmap designed to meet your business goals and elevate your digital presence.</p>
							<p>The strategic plan outlines:</p>
							<ul>
								<li>
									<h3>A website strategy</h3>
									<p>We plan a customized approach that aligns with your business objectives, from creating a seamless user experience to SEO and conversions.</p>
								</li>
								<li>
									<h3>Tools to enhance, replace or remove</h3>
									<p>We recommend updates or replacements to your current technology with an aim towards streamlining your operations and reducing inefficiencies.</p>
								</li>
								<li>
									<h3>Processes that can be enhanced or automated</h3>
									<p>We note the process updates that can help you save time, reduce manual errors, and improve consistency across your workflows.</p> 
								</li>
								<li>
									<h3>New tools or processes to resolve issues</h3>
									<p>Introducing innovative solutions to tackle existing challenges and unlock new opportunities for growth and efficiency.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><span>Stage 3:</span>&nbsp;<strong><em>Building and Implementation</em></strong></h2>
						<div className="step-content">
							<p>With the strategic plan in place, we transition into the implementation phase. This is where ideas become reality, as we focus on bringing the vision to life through new tools, streamlined processes, and custom solutions tailored to your business.</p>
							<p>Key tasks in this phase may include:</p>
							<ul>
								<li>
									<h3>Building or enhancing your website:</h3>
									<p>We ensure your website operates at peak performance, delivering a user-friendly experience that aligns perfectly with your business objectives and drives results.</p>
								</li>
								<li>
									<h3>Creating custom software solutions:</h3>
									<p>Sometimes your needs can't be fulfilled with an off-the-shelf solution. In these instances we design and build custom software that aligns perfectly with your unique workflows and operational needs.</p>
								</li>
								<li>
									<h3>Updating and creating processes:</h3>
									<p>Time to refine the identified processes and establish any needed new ones to ensure your team operates efficiently, with minimal disruptions and maximum productivity.</p>
								</li>
								<li>
									<h3>Setting up new tools:</h3>
									<p>If the strategy required any new tools - we get them set up and configured to optimize your operations, automate tasks, and resolve the challenges identified in earlier stages.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><span>Stage 4:</span>&nbsp;<strong><em>Integration and Optimization</em></strong></h2>
						<div className="step-content">
							<p>Building on the implementation phase, the fourth stage focuses on aligning your tools and processes for seamless collaboration. This ensures that your digital ecosystem operates efficiently and supports your business goals.</p>
							<p>Key tasks in this phase include:</p>
							<ul>
								<li>
									<h3>Data cleanup:</h3>
									<p>We take the data you have and remove duplicates, merge records and add necessary details.</p>
								</li>
								<li>
									<h3>Configuring standard integrations:</h3>
									<p>Setting up essential tools like email marketing platforms, customer relationship management (CRM) systems, and inventory management software to communicate effectively and streamline your operations.</p>
								</li>
								<li>
									<h3>Creating custom integrations:</h3>
									<p>If a necessary integration doesn't have a standard pathway - we implement a tailored one.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><span>Stage 5:</span>&nbsp;<strong><em>Automation and Finalization</em></strong></h2>
						<div className="step-content">
							<p>In this final stage of the implementation process, we focus on streamlining your workflows and automating as many steps as possible. The goal is to enhance efficiency, reduce manual effort, and ensure your processes are optimized for long-term success.</p>
							<p>Key tasks in this phase include:</p>
							<ul>
								<li>
									<h3>Automate additional manual processes:</h3>
									<p>While working through stage 3 and 4, more manual processes are often discovered. At this stage we make sure that these are automated as well.</p>
								</li>
								<li>
									<h3>Polish new or optimized processes:</h3>
									<p>Now that we have implemented updates to the processes, we run through them again and fine-tune them to ensure they are seamless, effective, and aligned with your business needs.</p>
								</li>
								<li>
									<h3>Test all processes to ensure optimization:</h3>
									<p>We conduct rigorous testing to identify any issues and make adjustments to guarantee everything runs smoothly and reliably.</p>
								</li>
							</ul>
							<p>By the end of this process, your systems will be running at peak efficiency, supported by robust automation and carefully refined processes. You'll be equipped with a streamlined digital infrastructure designed to save time, reduce errors, and maximize productivity.</p>
						</div>
					</div>
				</div>
				<div className='cta-block'>
					<div className="cta-items">
						<ul>
							<li className='cta-action'>
								<h3>Ready to take your business to the next level?</h3>
								<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Approach;