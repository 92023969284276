import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './CaseStudies.css';

function CaseStudies() {
	useEffect(() => {
		document.title = "Success Stories for Specialized Tool and Equipment Providers";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>Success in Action: Our Case Studies</h1>
			</Header>
			<div className="page-body case-studies">
                <p>Real results come from tailored solutions. Our case studies showcase how we've helped specialized tools and equipment providers overcome challenges, streamline processes, and achieve remarkable growth.</p>
				<p>Take a look at how our five-stage approach has driven success for businesses like yours. Ready to create your own success story? Let's start the conversation.</p>
                <div className="step-containers">
				<div className="step-container">
						<h2 className="step-title">Stage Partners<br /><strong><em>Stabilizing and Scaling for Hyper Growth</em></strong></h2>
						<div className="step-content">
							<h3>The Challenge</h3>
							<p>Stage Partners had outgrown their Shopify site, and needed a platform that would give them the customization they required for their purchasing process. They worked with their developer to transition to Magento 2.</p>
							<p>The new site faced significant hurdles: stability issues from shared hosting, missing critical updates, and an increasingly complex infrastructure. With a rapidly growing business, they needed a partner to improve reliability, scalability, and customer experience.</p>
							<Link to="/work/case-studies/scaling-stage-partners" className="work-button">Read the Full Study</Link>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title">Construction Equipment Supplier<br /><strong><em>From Unstable Foundations to Record-Breaking Online Sales</em></strong></h2>
						<div className="step-content">
							<h3>Client Background</h3>
							<p>Our client, a leading supplier of specialized tools and equipment for the construction and infrastructure industries, sought to modernize their operations with a Magento 2 eCommerce platform integrated with their Prophet21 ERP system. However, the journey to achieving their goals was far from smooth.</p>
							<h3>The Challenge</h3>
							<p>The new Magento 2 website, built by a previous development firm, faced significant issues from the start:</p>
							<ul>
								<li>Unreliable Uptime: The site was attractive but plagued by stability problems, frequently going offline during peak business hours.</li>
								<li>Unfinished Development: Critical features and promised deliverables were left incomplete when the site went live.</li>
								<li>Extended Downtime: After six months of attempts to resolve these issues with their initial firm, the problems persisted, negatively impacting their operations and customer trust.</li>
							</ul>
							<Link to="/work/case-studies/unstable-foundations" className="work-button">Read the Full Study</Link>
						</div>
					</div>
				</div>
				<div className='cta-block'>
					<div className="cta-items">
						<ul>
							<li className='cta-action'>
								<h3>Ready to take your business to the next level?</h3>
								<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default CaseStudies;