import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function JAY() {
	useEffect(() => {
		document.title = "Construction Equipment Supplier - From Unstable Foundations to Record-Breaking Online Sales";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>Study: From Unstable Foundations to Record-Breaking Online Sales</h1>
			</Header>
			<div className="page-body post-body case-studies">
				<div className="step-containers">
					<article>
						<h2>Client Background</h2>
						<p>A leading supplier of specialized tools and equipment for the construction and infrastructure industries sought to modernize their operations with a Magento 2 eCommerce platform integrated with their Prophet21 ERP system.</p>
						<p>They selected a firm they had worked with previously. However - that firm had recently started outsourcing their development work.</p>
						<p>As a result, the journey to achieving their goals was far from smooth.</p>
						<h2>The Challenge</h2>
						<p>The new Magento 2 website faced significant issues from the start:</p>
						<ul>
							<li>
								<h3>Unreliable Uptime:</h3>
								<p>The site was plagued by stability problems, frequently going offline during peak business hours.</p>
							</li>
							<li>
								<h3>Unfinished Development:</h3>
								<p>Critical features and promised deliverables were left incomplete when the site went live.</p>
							</li>
							<li>
								<h3>Extended Downtime:</h3>
								<p>After several months of attempts to resolve these issues with their initial firm, the problems persisted, negatively impacting their operations and customer trust.</p>
							</li>
						</ul>
						<h2>The Turning Point</h2>
						<p>Desperate for a solution, they partnered with Assisted Innovations to address their challenges and stabilize their online presence.</p>
						<h2>Our Approach</h2>
						<p>Assisted Innovations immediately began a comprehensive assessment of the website. The following steps were taken to turn the situation around:</p>
						<ol>
							<li>
								<h3>Quick Wins for Stability</h3>
								<p>In this kind of situation - the critical first step is to find the small wins that have big impact.</p>
								<p>Assisted Innovations immediately diagnosed several small issues that were contributing to the frequent outages.</p>
								<p>We resolved these issues and also implemented additional configuration updates to improve the site's ability to remain online during business hours.</p>
							</li>
							<li>
								<h3>Infrastructure Overhaul</h3>
								<p>With the quick wins out of the way - we turned our attention to the infrastructure.</p>
								<p>It was clear that the existing web hosting environment was insufficient for a Magento 2 platform.</p>
								<p>And so - we collaborated with the client's internal IT team to migrate the site to AWS services, which provided a robust and scalable hosting solution.</p>
							</li>
							<li>
								<h3>Codebase Rehabilitation</h3>
								<p>Finally - the website had a measure of stability and was staying online for the business day.</p>
								<p>But - the job wasn't finished yet.</p>
								<p>Assisted Innovations conducted a thorough code review and identified several critical areas that needed attention:</p>
								<ul>
									<li>The core Magento files, along with several Plugins had been modified directly - preventing the application of critical security updates.</li>
									<li>Best practices had not been followed - causing a need for increased resources on the hosting.</li>
								</ul>
								<p>Assisted Innovations proceeded to rework the affected code. We moved modifications out of core Magento and plugin code, instead implementing them in a manner consistent with best practices for Magento. We then went through the custom code and implemented best practices throughout.</p>
							</li>
						</ol>
						<h2>The Results</h2>
						<h4>Assisted Innovations' expertise helped this client transform their Magento 2 website into a high-performing sales tool.</h4>
						<ul>
							<li>
								<h3>Improved Stability</h3>
								<p>The site achieved consistent uptime, restoring customer trust and ensuring reliable operations.</p>
							</li>
							<li>
								<h3>Record-Breaking Sales</h3>
								<p>Within months, the client began setting and breaking their highest monthly online sales records, peaking at over $500,000 in a single month.</p>
							</li>
							<li>
								<h3>Long-Term Growth</h3>
								<p>The platform's performance and stability allowed the client to focus on strategic growth rather than constant troubleshooting.</p>
							</li>
						</ul>
						<h2 className="step-title"><strong><em>Key Takeaways for Specialized Tool Providers</em></strong></h2>
						<p>This case study highlights critical lessons for companies that manufacture and supply specialized tools and equipment:</p>
						<ol>
							<li>
								<h4>Choose the Right Partner:</h4>
								<p>Investing in a firm with expertise in your platform and industry can save significant time and resources.</p>
							</li>
							<li>
								<h4>Prioritize Stability:</h4>
								<p>A visually appealing site is important, but uptime and reliability are paramount for building customer trust.</p>
							</li>
							<li>
								<h4>Invest in Scalable Infrastructure:</h4>
								<p>Proper hosting solutions tailored to your platform's requirements are essential for long-term success.</p>
							</li>
							<li>
								<h4>Adhere to Best Practices:</h4>
								<p>Maintaining clean and well-structured code ensures better performance and easier upgrades.</p>
							</li>
						</ol>
					</article>
				</div>
			</div>
		</>
	)
}

export default JAY;