import React, { useEffect } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './About.css';

function About() {
	useEffect(() => {
		document.title = "About Our Web and Application Development Agency";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>About Assisted Innovations</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div className="step-container about-1-container">
						<div>
							<p>After years of working at agencies that prioritized profits over their clients, we had enough. We saw businesses left with underperforming tools and wasted potential, while employees were stuck in roles that didn't foster their growth.</p>
							<p>That's why we launched Assisted Innovations—to build a team relentlessly focused on growth. Not just ours, but <strong>everyone's</strong>.</p>
							<p>Whether you're a client or a team member, our mission is to help you grow. And when the time comes for you to move on, we'll be there to support your next step.</p>
							<p>For us, growth isn't about keeping clients or employees forever—it's about helping them succeed, no matter what.</p>
							<p>Because we believe that progressing to the next level is something that should be celebrated!</p>
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><strong><em>Our Experience with Growth</em></strong></h2>
						<div className="step-content">
							<p>With nearly two decades of experience, our team has helped businesses evolve their processes, scale operations, and achieve what once seemed impossible.</p>
							<p>Here are a couple of examples from our background:</p>
							<ol>
								<li>
									<h3>Automation leads to 800% client increase:</h3>
									<p>Our leader worked with a company that onboarded just one client a year, taking six months to complete each process. They needed to hire additional staff with every 2-3 new clients in order to manage the workload, which slowed their growth.</p>
									<p>After implementing strategic process changes and custom automation, they reduced onboarding time to just a few hours. This enabled them to scale from six to 50 clients in five years without significantly increasing their workforce. The results? An 800% growth in client base, reduced stress for the team, and significant cost savings.</p>
								</li>
								<li>
									<h3>From instability to $500k online sales:</h3>
									<p>We were brought in to help a client that was struggling to keep their website online during the day. The company that had built it did a terrible job, and they needed a lot of help.</p>
									<p>Over the course of a few months, we helped them migrate the site to AWS cloud hosting and cleaned up a lot of the code. These changes brought stability, improved customer trust, and ultimately led them to a series of months that were their largest ever online sales months; peaking with $500k in a single month.</p>
								</li>
							</ol>					
						</div>
					</div>
					<div className="step-container">
						<h2 className="step-title"><strong><em>Business Evolution</em></strong></h2>
						<div className="step-content">
							<p>This kind of transformation is what we call Business Evolution.</p>
							<p>It's more than growth—it's a fundamental shift in how a company operates, setting the stage for sustainable success.</p>
							<p>Our mission at Assisted Innovations is to help our clients find this shift.</p>
							<p>To help our clients evolve.</p>
							<p>Every business has its own path to evolution. What will yours look like?</p>
						</div>
					</div>
				</div>
				<div className='cta-block'>
					<div className="cta-items">
						<ul>
							<li className='cta-action'>
								<h3>Ready to Evolve?</h3>
								<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;