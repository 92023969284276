import React from 'react';
import { Route, Routes } from 'react-router-dom';

import JAY from './studies/JAY.js';
import STP from './studies/STP.js';

import './Studies.css';

function Studies() {
	return (
		<>
			<Routes>
				<Route path="/unstable-foundations" element={<JAY />} />
				<Route path="/scaling-stage-partners" element={<STP />} />
			</Routes>
			<div className='cta-block'>
				<div className="cta-items">
					<ul>
						<li className='cta-action'>
							<h3>Ready to take your business to the next level?</h3>
							<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
							<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}

export default Studies;