import { useEffect } from 'react';
import Carousel from "react-multi-carousel";

import trackPage from '../../utils/trackPage';

import "react-multi-carousel/lib/styles.css";
import './Home.css';
import Banner from './banner.mp4';
import Stars from './stars.svg';

function Home() {
	const reviews = [
		{
			name: 'Gavin J, The Jaybro Group',
			quote: 'We needed immediate, professional technical support and within minutes Assisted Innovation began the coverage we needed. Excellent service and communication, deep driving into the root cause of numerous issues inside our large ecommerce store. The insights have proven invaluable.'
		},
		{
			name: 'Kerry S, All American Heating',
			quote: 'Assisted Innovations is incredibly easy to work with. Ross is knowledgeable and provides solid options and recommendations. Work is performed quickly and correctly. Would solidly recommend working with Ross and his team to anyone looking for development work for the web or applications.'
		},
		{
			name: "Dan N, Lazurite Inc",
			quote: "Assisted Innovations did a great job helping us upgrade our outdated system and then again jumping in to handle some errors caused by mistakes we made on very short notice."
		},
		{
			name: 'Drew B',
			quote: 'Assisted Innovations has truly been a pleasure to work with. They have always looked out for what the best solutions are for my company and not what is best for their company. I will continue working with them in the future.'
		}
	]

	const responsive = {
		largeDesktop: {
			breakpoint: { max: 9999, min: 1600 },
			items: 4
		},
		desktop: {
			breakpoint: { max: 1600, min: 1200 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1200, min: 800 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 800, min: 0 },
			items: 1
		}
	};


	useEffect(() => {
		document.title = "Turning specialized tools into online sales for more than 20 years";
		trackPage();
	});

	return (
		<>
			<header className="home-header">
				<video preload autoPlay muted loop id="banner">
					<source src={Banner} type="video/mp4"></source>
				</video>
				<div className="content">
					<h1>Creating <strong><em>digital success</em></strong> for specialized tools</h1>
					<span>Let your website do the heavy lifting, so you can focus on what you do best</span>
				</div>
			</header>
			<main className="home-content">
				<div className="cta-block light-block">
					<h2>Your Problems, <strong><em>Solved</em></strong></h2>
					<div className="cta-items">
						<p>
							We understand the unique challenges of selling specialized tools and equipment.
						</p>
						<ul>
							<li>
								<h3>Showcase</h3>
								<p>Show off your products with advanced, searchable catalogs with intuitive filtering and specification-based searches</p>
							</li>
							<li>
								<h3>Streamline</h3>
								<p>Simplify the ordering process with an optimized eCommerce platform or bulk order portal</p>
							</li>
							<li>
								<h3>Be Seen</h3>
								<p>Get noticed in search engines with an SEO-optimization that works around the clock</p>
							</li>
							<li>
								<h3>Grow</h3>
								<p>Expand your product lines and enter new markets with a digital platform that scales with you</p>
							</li>
							<li className='cta-action'>
								<h3>Let's Build Your Next Solution</h3>
								<p>Partner with us to push your website to work as hard as you do.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
				<div className="cta-block dark-block services-cta">
					<h2><strong><em>Real Results</em></strong>, Real Feedback</h2>
					<div className="cta-items">
						<p>Our work speaks for itself, but our clients speak even louder. See how we've made a difference for businesses like yours.</p>
						<Carousel responsive={responsive} arrows={false} showDots infinite swipeable draggable autoPlay autoPlaySpeed={5000} keyBoardControl>
							{reviews.map((review, index) => {
								const { name, quote } = review
								const count = index + 1
								return (
									<>
										<img src={Stars} alt="5 star rating" style={{ width: '50%' }} />
										<blockquote key={count}>
											{quote}<br /><br />
											-- {name}
										</blockquote>
									</>
								)
							})}
						</Carousel>
					</div>
				</div>
				<div className='cta-block'>
					<h2>Let's Partner for <strong><em>Your Success</em></strong></h2>
					<div className="cta-items">
						<ul>
							<li className='cta-action'>
								<h3>Ready to take your business to the next level?</h3>
								<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
								<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
							</li>
						</ul>
					</div>
				</div>
			</main>
		</>
	)
}

export default Home;